import { ApiV2Errors } from '@ha/api/v2/errors';
import { defineKey as T } from '@ha/intl';

export const ErrorMessages = {
  ...ApiV2Errors,
  'cannot create user while other user is logged in': T(
    'Cannot create user while other user is logged in',
  ),
  'Account deleted': T('Account deleted'),
  email_already_registered: T('Email is already registered'),
  'empty payload': T('Empty payload'),
  'value is required': T('This field is required'),
  enter_phone: T('Enter phone'),
  enter_email: T('Enter email'),
  enter_first_name: T('Enter first name'),
  enter_last_name: T('Enter last name'),
  password_short: T('Password is too short'),
  something_went_wrong: T('Something went wrong'),
  passwords_do_not_match: T('Passwords do not match'),
  cannot_parse_birth_date: T('Cannot parse birth date'),
  'Forbidden characters: <>': T('Forbidden characters: <>'),
  '500 - Internal Server Error': T('Something went wrong'),
  'payment method cannot be created': T('Payment method cannot be created'),
  emailOrPasswordIncorrect: T('Incorrect email or password'),
  maxAttemptsReached: T(
    'Not able to login. Please reach out to our support team',
  ),
  'cannot resolve address, communication error': T(
    'Please fill in the complete address including the street name, house number, city, and country.',
  ),
  'cannot resolve address': T(
    'Please fill in the complete address including the street name, house number, city, and country.',
  ),
  'cannot resolve address, missing street': T(
    'Please fill in the complete address including the street name, house number, city, and country.',
  ),
  invalid_first_name: T('sign_up.form.first_name.error_forbidden_characters'),
  invalid_last_name: T('sign_up.form.last_name.error_forbidden_characters'),
  'country not supported': T('listings.create.country.not_supported'),
  _maximum_length_exceeded: T('listingDescription.LengthError'),
  account_docs_upload_error: T('account.docs.upload.error'),
  user_too_many_documents: T('error.exceeded_doc_limit'),
};

export const getErrorMessage = (error: string): string =>
  ErrorMessages[error] || error;
