/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';

import { Box } from '@mui/material';
import { FORM_ERROR } from 'final-form';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Button, InputField, Typography } from '@hbf/dsl/core';
import { Cancel } from '@hbf/icons/brand-bold';

import { RenderDangerously } from 'ha/helpers/RenderDangerously';
import { AuthPageQueryActions, AuthPageTarget } from 'ha/helpers/UrlResolver';
import { useIntl } from 'ha/i18n';

import { PageDrawer } from '../../../../SearchRedesign/components/PageDrawer/PageDrawer';
import { getCityLocalized } from '../../../selectors';

import { SearchAlertFilterDetails } from './SearchAlertFilterDetails';
import {
  FormErrorKeys,
  SearchAlertsExperimentProps,
  TypographyProps,
} from './types';

const useStyles = makeStyles()(theme => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/6'),
  },
  link: {
    fontWeight: 'bolder',
  },
}));

export const SearchAlertsForm = ({
  form,
}: Pick<SearchAlertsExperimentProps, 'form'>) => {
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();
  const cityLocalized = useSelector(getCityLocalized);

  const formApi = useForm();

  const hasAlreadyUsedEmailError =
    form?.hasSubmitErrors &&
    form?.submitErrors?.[FORM_ERROR] === FormErrorKeys.EMAIL_ALREADY_USED;

  const idpUrls = React.useMemo(() => {
    const signInUrl = urlResolver.getOAuthSigninUrl(window?.location.href, {
      target: AuthPageTarget.Tenant,
      action: AuthPageQueryActions.CREATE_SEARCH_ALERT,
    });

    const signUpUrl = urlResolver.getOAuthSignupUrl(window?.location.href, {
      target: AuthPageTarget.Tenant,
      action: AuthPageQueryActions.CREATE_SEARCH_ALERT,
    });

    return { signInUrl, signUpUrl };
  }, [urlResolver]);

  return (
    <form
      onSubmit={form.handleSubmit}
      data-test-locator="SearchAlertsDrawerWithoutAuth.SearchAlertsForm"
    >
      <PageDrawer.Header>
        {T('unauthorised_alerts.modal.get_alerts')}
      </PageDrawer.Header>
      <PageDrawer.Content>
        <Box className={classes.contentBox}>
          <Typography variant="heading/desktop/h4-semibold">
            {T('unauthorised_alerts.modal.get_alerts.heading')}
          </Typography>

          <Box display="grid" gap={theme => theme.spacing(2)}>
            <SearchAlertFilterDetails />
          </Box>

          <Box>
            <Field name="email">
              {({ input, meta }) => {
                const hasError =
                  meta.touched &&
                  (Boolean(meta.error) || hasAlreadyUsedEmailError);
                return (
                  <InputField
                    size="lg"
                    error={hasError}
                    disabled={form.submitting}
                  >
                    <InputField.Label error={hasError}>
                      {T('label.email')}
                    </InputField.Label>
                    <InputField.Input
                      {...input}
                      fullWidth
                      type="email"
                      endIcon={
                        hasAlreadyUsedEmailError ? (
                          <Cancel
                            sx={{ cursor: 'pointer' }}
                            onClick={formApi.restart}
                          />
                        ) : null
                      }
                    />
                    {hasError && (
                      <InputField.HelperText>
                        {meta.error && T(meta.error as string)}
                        {hasAlreadyUsedEmailError &&
                          T(FormErrorKeys.EMAIL_ALREADY_USED, cityLocalized)}
                      </InputField.HelperText>
                    )}
                  </InputField>
                );
              }}
            </Field>
          </Box>

          {hasAlreadyUsedEmailError && (
            <Box>
              <RenderDangerously<TypographyProps>
                renderWith={Typography}
                variant="body/lg-regular"
                data-test-locator="SearchAlertsDrawerWithoutAuth.SearchAlertsForm.EmailAlreadyUsedError"
              >
                {T('unauthorised_alerts.modal.get_alerts.manage_alerts', {
                  link_prefix1: `<a href="${idpUrls.signInUrl}" class=${classes.link}>`,
                  link_suffix1: '</a>',
                  link_prefix2: `<a href="${idpUrls.signUpUrl}" class=${classes.link}>`,
                  link_suffix2: '</a>',
                })}
              </RenderDangerously>
            </Box>
          )}

          <Box>
            <Button
              type="submit"
              disabled={
                (form.touched?.email && form.hasValidationErrors) ||
                hasAlreadyUsedEmailError
              }
              loading={form.submitting}
            >
              {T('unauthorised_alerts.modal.get_alerts.cta')}
            </Button>
          </Box>
        </Box>
      </PageDrawer.Content>
    </form>
  );
};
