import { FormRenderProps } from 'react-final-form';

import { Typography } from '@hbf/dsl/core';
import { defineKey } from '@ha/intl';

export interface FormValues {
  email: string;
}

export type TypographyProps = React.ComponentProps<typeof Typography>;

export interface SearchAlertsExperimentProps {
  open: boolean;
  onClose: () => void;
  form: FormRenderProps<FormValues>;
}

export const FormErrorKeys = {
  INVALID_EMAIL: defineKey('login.error.invalid_email'),
  EMAIL_ALREADY_USED: defineKey(
    'unauthorised_alerts.modal.get_alerts.error.email_already_used',
  ),
};
