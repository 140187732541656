import React from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import isPlainObject from 'lodash-es/isPlainObject';

import { CurrencyFormat } from '@ha/intl';
import { Typography } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

import { SEARCH_FILTER_PROPERTY_TYPE_TRANSLATIONS } from 'ha/modules/Search/translations/propertyType';
import { getCityLocalized } from 'ha/pages/Search/selectors';
import { getSearchAlertParams } from 'ha/pages/Search/selectors/searchAlertSelectors';

const getFiltersCount = (filtersObj: Record<string, unknown>): number => {
  return Object.entries(filtersObj).reduce((acc, [, value]) => {
    // Handle empty arrays and null values
    if (Array.isArray(value)) {
      return value.length > 0 ? acc + 1 : acc;
    }
    if (!value) {
      return acc;
    }
    if (isPlainObject(value)) {
      // Recursively call for nested objects
      return acc + getFiltersCount(value as Record<string, unknown>);
    }
    // Count non-empty arrays and other valid values
    return acc + 1;
  }, 0);
};

export const SearchAlertFilterDetails = () => {
  const { T, formatCurrency } = useIntl();
  const cityLocalized = useSelector(getCityLocalized);
  const {
    filters: {
      categories,
      priceMin,
      priceMax,
      currency,
      radius,
      searchArea,
      ...restFilters
    },
  } = useSelector(getSearchAlertParams);

  const getPriceFilter = () => {
    if (priceMin && priceMax && currency) {
      return [
        formatCurrency(
          priceMin,
          currency,
          CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING,
        ),
        formatCurrency(
          priceMax,
          currency,
          CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING,
        ),
      ].join(' - ');
    }

    return T('any');
  };

  const restFiltersCount = React.useMemo(
    () => getFiltersCount(restFilters),
    [restFilters],
  );

  return (
    <React.Fragment>
      <Typography variant="body/md-semibold">
        {T('unauthorised_alerts.modal.get_alerts.search_prefs')}
      </Typography>
      <Box>
        <Typography>
          {T(
            'unauthorised_alerts.modal.get_alerts.search_prefs.city',
            cityLocalized,
          )}
        </Typography>
        <Typography>
          {T(
            'unauthorised_alerts.modal.get_alerts.search_prefs.rent',
            getPriceFilter(),
          )}
        </Typography>
        <Typography>
          {T(
            'unauthorised_alerts.modal.get_alerts.search_prefs.property',
            categories
              ?.map(
                category => SEARCH_FILTER_PROPERTY_TYPE_TRANSLATIONS[category],
              )
              .join(', ') || T('any'),
          )}
        </Typography>
        {restFiltersCount ? (
          <Typography>
            {T(
              'unauthorised_alerts.modal.get_alerts.search_prefs.more_filters',
              restFiltersCount,
            )}
          </Typography>
        ) : null}
      </Box>
    </React.Fragment>
  );
};
